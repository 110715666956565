import React from 'react';
import WhyChooseUsSection from '../components/organisms/ChooseUsSection';

const whyChooseUsData = {
  title: 'Why Choose Us',
  heading: 'AI Solutions Trusted Globally',
  description:
    'At Quantum Catalyst, we leverage cutting-edge AI technologies across verticals to drive transformative outcomes. Our expertise ensures innovative, scalable, and ethical solutions tailored to diverse business needs.',
  features: [
    '25+ Trusted Clients Worldwide',
    'Dedicated Multi-Disciplinary Teams',
    'Focused on Delivering Measurable Outcomes',
    'Experts in Web, Cloud, and AI Solutions'
  ],
  image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/workflow.png'
};

const ChooseUsPage = () => {
  return (
    <div>
      <WhyChooseUsSection
        title={whyChooseUsData.title}
        heading={whyChooseUsData.heading}
        description={whyChooseUsData.description}
        features={whyChooseUsData.features}
        image={whyChooseUsData.image}
      />
    </div>
  );
};

export default ChooseUsPage;
