import React from 'react';
import ServicesSection from '../components/organisms/ServicesSection';

const servicesData = [
  {
    title: 'Artificial Intelligence',
    description: 'Empowering businesses with advanced AI solutions that drive innovation, automate processes, and create transformative impacts across industries.',
    icon: '/path-to-ai-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service1.png'
  },
  {
    title: 'Machine Learning',
    description: 'Unlocking the potential of data through machine learning models that enable predictive analytics, personalization, and smarter decision-making.',
    icon: '/path-to-ml-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service2.png'
  },
  {
    title: 'Cloud Datacenter',
    description: 'Optimizing scalability, security, and performance with cloud-centric infrastructure tailored for modern businesses.',
    icon: '/path-to-cloud-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service3.png'
  },
  {
    title: 'Data Science',
    description: 'Transforming raw data into actionable insights using data science techniques that drive growth and operational efficiency.',
    icon: '/path-to-ds-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service4.png'
  },
  {
    title: 'Cyber Security',
    description: 'Ensuring robust protection for your digital assets with cutting-edge cybersecurity solutions to mitigate risks and safeguard data.',
    icon: '/path-to-cs-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service5.png'
  },
  {
    title: 'DevOps',
    description: 'Accelerating development and deployment cycles with seamless integration of DevOps practices for agile and efficient workflows.',
    icon: '/path-to-devops-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service6.png'
  }
];

const HomePage = () => {
  return (
    <div>
      <ServicesSection services={servicesData} />
    </div>
  );
};

export default HomePage;
