// src/pages/Home.js
import React from 'react';
import Navbar from '../components/molecules/Navbar';
import HeroSection from '../components/organisms/HeroSection';
import ServicesSection from '../components/organisms/ServicesSection';
import TestimonialsSection from '../components/organisms/TestimonialsSection';
import Footer from './Footer';
import About from './About';
import Services from './Services';
import ChooseUsPage from './ChooseUs';
import Testimonials from './Testimonials';
import ContactUsSection from './Contact';
import WorkFlow from './WorkFlow';

const Home = () => {
  const slides = [
    {
      title: "Transforming Ideas into Impact: AI Solutions for the Tomorrow.",
      subtitle: "Catalyzing Your Vision with AI",
      description: "Leverage the power of AI-driven insights and cutting-edge solutions to accelerate innovation and business transformation.",
      buttonText: "Get Started",
      image: "https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/slick01.png",
    },
    {
      title: "One Platform, Infinite Possibilities",
      subtitle: "Innovation at Scale",
      description: "Explore how generative AI and adaptive systems are revolutionizing industries, streamlining operations, and driving unmatched growth.",
      buttonText: "Learn More",
      image: "https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/slider2.png",
    },
    {
      title: "Redefining the Future with AI",
      subtitle: "Your Partner in Innovation",
      description: "Discover Quantum Catalyst's holistic approach to AI implementation, from strategy to execution, ensuring measurable impact.",
      buttonText: "Discover More",
      image: "https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/slider3.png",
    }
  ];
  
  return (
    <div className="home-page">
      <HeroSection slides={slides} />
      <About/>
      <Services />
      <ChooseUsPage/>
      <WorkFlow/>
      <Testimonials />
      <ContactUsSection/>
    </div>
  );
};

export default Home;
