// src/pages/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <section className="about-us-section">
      <h3 className="about-us-subtitle">About Us</h3>
      <h2 className="about-us-title">Driving Innovation Through AI Solutions</h2>
      <p className="about-us-description">
        At Quantum Catalyst, we specialize in empowering businesses with cutting-edge AI technologies to unlock their true potential. 
        Our mission is to deliver scalable, ethical, and impactful AI solutions that drive measurable results across industries. 
        With a deep focus on innovation, adaptability, and collaboration, we aim to bridge the gap between visionary ideas and actionable outcomes.
      </p>
      <p className="about-us-description">
        Leveraging Gartner's proven frameworks and emerging AI trends, we tailor strategies to meet the unique needs of our clients, 
        ensuring sustainable growth and a competitive edge in a rapidly evolving digital world.
      </p>
    </section>
  );
};

export default About;
