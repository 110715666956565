import React from 'react';
import WorkflowSection from '../components/organisms/WorkFlowSection';

const workflowData = {
  image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/client.png',
  subtitle: 'Our Workflow',
  title: 'Driving Innovation with Gartner-Inspired AI Processes',
  description: 'Our workflow leverages industry-leading AI methodologies to deliver tailored, data-driven solutions that enhance efficiency and drive growth across domains.',
  steps: [
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf1.png',
      title: 'Research & Strategy',
      description: 'We employ Gartner’s 4A Model to analyze client challenges and define clear, actionable AI strategies tailored to specific business needs.'
    },
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf2.png',
      title: 'Data Insights & Prep',
      description: 'Using advanced analytics and semantic AI technologies, we transform raw data into meaningful insights that drive decision-making.'
    },
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf3.png',
      title: 'Targeted AI Outcome',
      description: 'Our AI engineering expertise ensures seamless deployment of solutions, optimizing processes with generative and adaptive AI systems.'
    },
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf4.png',
      title: 'Continuous Optimization',
      description: 'We monitor and refine AI systems using advanced monitoring tools, ensuring consistent performance, scalability, and ROI for our clients.'
    }
  ]
};

const WorkFlow = () => {
  return (
    <div>
      <WorkflowSection workflowData={workflowData} />
    </div>
  );
};

export default WorkFlow;
