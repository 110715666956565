import React from 'react';
import Slider from 'react-slick';
import './TestimonialsSection.css';

const TestimonialSection = ({ testimonials }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 2 cards per slide
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1, // Show 1 card per slide on smaller screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="testimonial-section">
      <h3 className="testimonial-subtitle">Testimonials</h3>
      <h2 className="testimonial-title">What Our Clients Say</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-header">
              <img
                src={testimonial.image}
                alt={`${testimonial.name}'s avatar`}
                className="testimonial-image"
              />
              <div className="testimonial-icon">💬</div>
            </div>
            <p className="testimonial-text">“ {testimonial.text} ”</p>
            <div className="testimonial-rating">
              {'⭐'.repeat(testimonial.rating)}
            </div>
            <p className="testimonial-name">{testimonial.name}</p>
            <p className="testimonial-location">{testimonial.location}</p>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default TestimonialSection;
