import React from 'react';
import TestimonialSection from '../components/organisms/TestimonialsSection';

const testimonialData = [
  {
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/image.png',
    text: 'Our business processes improved by 40%, saving 30% in effort and achieving an annual cost reduction of $500K through AI-driven automation.',
    rating: 5,
    name: 'Samir',
    location: ' CEO Practice Mangement System USA',
  },
  {
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/image.png',
    text: 'Quantum Catalyst’s data science expertise provided actionable insights, boosting decision-making efficiency by 25% and unlocking new revenue streams.',
    rating: 5,
    name: 'Suneel Kandru',
    location: 'CEO Bitcraft USA',
  },
  {
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/image.png',
    text: 'By optimizing our Azure architecture, we saved 30% on cloud costs while achieving seamless scalability for our application during peak demand.',
    rating: 5,
    name: 'Umar Sherief',
    location: 'CTO Insurance provider UK',
  }
];

const Testimonials = () => {
  return (
    <div>
      <TestimonialSection testimonials={testimonialData} />
    </div>
  );
};

export default Testimonials;
