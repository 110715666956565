import React from 'react';
import FooterSection from '../components/molecules/FooterSection';

const brandData = {
  logo: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/QCLogo.png',
  description: 'Unlike conventional tools that rely on repetitive templates, our solutions are designed to deliver unique, innovative outcomes tailored to your needs, setting a new standard in precision and creativity',
  socialLinks: [
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/facebook.png', url: 'https://facebook.com' },
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/twitter.png', url: 'https://twitter.com' },
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/linkdien.png', url: 'https://linkedin.com' },
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/google.png', url: 'https://plus.google.com' }
  ]
};

const footerColumns = [
  {
    heading: 'Useful Links',
    links: [
      { text: 'About', url: '#about' },
      { text: 'Services', url: '#services' },
      { text: 'Price', url: '#price' },
      { text: 'Contact', url: '#contact' },
      { text: 'Testimonial', url: '#testimonial' }
    ]
  },
  {
    heading: 'Generative AI Innovation',
    links: [
      { text: 'Generative AI', url: 'https://www.ibm.com/cloud/learn/generative-ai' },
      { text: 'Gen AI in Action', url: 'https://openai.com/dall-e' },
      { text: 'Advancements in AI Models', url: 'https://huggingface.co/models' },
      { text: 'Build with Gen AI', url: 'https://developers.google.com/learn/generative-ai' },
      { text: 'Success Stories with AI', url: 'https://www.microsoft.com/en-us/ai/ai-innovations' }
    ]
  },
  {
    heading: 'AI Apps',
    links: [
      { text: 'AI Platforms', url: 'https://cloud.google.com/vertex-ai' },
      { text: 'Applications of AI', url: 'https://www.accenture.com/us-en/services/applied-intelligence/overview' },
      { text: 'AI for Businesses', url: 'https://aws.amazon.com/machine-learning/business/' },
      { text: 'Custom AI Solutions', url: 'https://azure.microsoft.com/en-us/solutions/' },
      { text: 'AI Engineering', url: 'https://www.gartner.com/en/topics/ai-engineering' }
    ]
  }  
];

const Footer = () => {
  return (
    <div>
      <FooterSection brand={brandData} columns={footerColumns} />
    </div>
  );
};

export default Footer;
