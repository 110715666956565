import React from 'react';
import './Contact.css';

const ContactUsSection = () => {
  return (
    <section className="contact-us-section">
      <h2 className="contact-us-heading">Let’s Build Your AI-Driven Future</h2>
      <p className="contact-us-subheading">Reach out to explore tailored solutions that scale with your business needs.</p>
      <div className="contact-us-buttons">
        <button className="get-started-btn1">Get Started</button>
        <button className="contact-us-btn">Contact Us</button>
      </div>
    </section>
  );
};

export default ContactUsSection;
